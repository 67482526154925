import { useEffect, useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Form, Button, Input, SelectPicker, Tag, TagGroup, Loader, Panel, Container, Content, Stack, Heading } from "rsuite";
import SignatureCanvas from "react-signature-canvas";
import API from "../../utils/API";
import { format, parseISO, set } from "date-fns";

function WorkorderNew(props) {
  let navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [clients, set_clients] = useState([]);
  const [projects, set_projects] = useState([]);
  const [is_loaded, set_loaded] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  const project = watch("project");

  const filterProjects = async (inputValue) => {
    if (inputValue) {
      const response = await API.get(`/projects/search/${inputValue}`, { params: { is_active: true } });
      set_projects(
        response?.data?.data?.map(({ id, name, locations, status }) => ({
          value: id,
          label: name,
          locations,
          status,
        }))
      );
    }
  };

  useEffect(() => {
    API.get(`/clients`, { params: { limit: 200 } })
      .then((r) => {
        set_clients(r.data.data.map(({ id: value, name: label }) => ({ value, label })));
      })
      .catch((e) => console.log(e));

    reset({
      work_date: format(new Date(), "Y-MM-dd"),
      //   work_finish: format(new Date(), "Y-MM-dd"),
    });
    set_loaded(true);
  }, [reset, set_loaded]);

  const onSubmit = async (data) => {
    if (!data.project) {
      data.project = null;
    }
    if (data.work_date === "") {
      data.work_date = null;
    }
    if (data.work_start_time === "") {
      data.work_start_time = null;
    }
    if (data.field_address_line_1 == "") {
      data.field_address_line_1 = null;
    }
    if (data.field_address_line_2 == "") {
      data.field_address_line_2 = null;
    }
    if (data.field_customer == "") {
      data.field_customer = null;
    }
    console.log(data);
    await API.post("/workorders/field", data)
      .then((r) => navigate(`/workorders`))
      .catch((e) => console.log(e.response.data.errors));
  };

  if (!is_loaded) {
    return <Loader center content="Loading..." />;
  } else {
    return (
      <Container>
        {console.log("watched project value", project)}
        <Content>
          <Panel>
            <Stack alignItems="center" justifyContent="space-between">
              <Stack.Item>
                <Heading level={6}>Create Workorder</Heading>
              </Stack.Item>
            </Stack>
          </Panel>

          <Panel>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="project">
                <Form.ControlLabel>Project</Form.ControlLabel>
                <Controller
                  name="project"
                  autoComplete="off"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <SelectPicker
                      block
                      cleanable
                      autoComplete="off"
                      value={value}
                      data={projects ?? []}
                      onSearch={filterProjects}
                      onChange={(v) => onChange(v)}
                      labelKey="label"
                      valueKey="value"
                      renderMenuItem={(label, item) => (
                        <div>
                          <strong>{label}</strong>
                          <p>{item.description}</p>
                          <p>
                            {item?.locations[0]?.address_line_1} - {item?.locations[0]?.address_line_2}
                          </p>
                        </div>
                      )}
                    />
                  )}
                />
              </Form.Group>

              <Form.Group controlId="billing_customer">
                <Form.ControlLabel>Customer</Form.ControlLabel>
                <Controller
                  name="billing_customer"
                  rules={{ required: !project }}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <SelectPicker
                        data={clients}
                        block
                        value={value}
                        onChange={onChange}
                        // style={{ width: 224 }}
                        placeholder="Select a customer"
                        disabled={project ? true : false}
                        cleanable
                      />
                      {errors.billing_customer && <Form.HelpText style={{ color: "red" }}>{errors.billing_customer.message || "This field is required"}</Form.HelpText>}
                    </>
                  )}
                />
              </Form.Group>

              <Form.Group controlId="field_address_line_1">
                <Form.ControlLabel>Street Address</Form.ControlLabel>
                <Controller
                  name="field_address_line_1"
                  rules={{ required: !project }}
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input {...field} value={field.value || ""} disabled={project ? true : false} />
                      {errors.field_address_line_1 && <Form.HelpText style={{ color: "red" }}>{errors.field_address_line_1.message || "This field is required"}</Form.HelpText>}
                    </>
                  )}
                />
              </Form.Group>

              <Form.Group controlId="field_address_line_2">
                <Form.ControlLabel>Suburb</Form.ControlLabel>
                <Controller
                  name="field_address_line_2"
                  rules={{ required: !project }}
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input {...field} value={field.value || ""} disabled={project ? true : false} />
                      {errors.field_address_line_2 && <Form.HelpText style={{ color: "red" }}>{errors.field_address_line_2.message || "This field is required"}</Form.HelpText>}
                    </>
                  )}
                />
              </Form.Group>

              <Form.Group controlId="field_customer">
                <Form.ControlLabel>Site Customer</Form.ControlLabel>
                <Controller
                  name="field_customer"
                  rules={{ required: !project }}
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input {...field} value={field.value || ""} disabled={project ? true : false} />
                      {errors.field_customer && <Form.HelpText style={{ color: "red" }}>{errors.field_customer.message || "This field is required"}</Form.HelpText>}
                    </>
                  )}
                />
              </Form.Group>

              <Form.Group>
                <Button size="lg" block appearance="primary" type="submit" disabled={isSubmitting}>
                  Create Workorder
                </Button>
              </Form.Group>
            </Form>
          </Panel>
        </Content>
      </Container>
    );
  }
}

export default WorkorderNew;
